<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <PaymentsTable filters="true" />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import PaymentsTable from './elements/paymentsTable.vue'

export default {
  name: 'Payments',
  components: { PaymentsTable }
}
</script>
